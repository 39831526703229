@mixin report_card($border_color) {
  padding: 4em 0em;
  border-left: 3px solid $border_color;
  background-color: #f8f8f884;
  // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  background: #fbfbfb;
  box-shadow: 5px 5px 10px #dedede, -5px -5px 10px #ffffff;
  margin: 0.5em 0em;
}
@mixin panel_card($border_color) {
  padding: 1.5em 0em;
  border-left: 3px solid $border_color;
  background-color: #f8f8f884;
  // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  background: #fbfbfb;
  box-shadow: 5px 5px 10px #dedede, -5px -5px 10px #ffffff;
  margin: 0.5em 0em;
}
.rma_report_card {
  @include report_card(orange);
}
.order_report_card {
  @include report_card(purple);
}
.instock_report_card {
  @include report_card(royalblue);
}
.adjustment_report_card {
  @include report_card(rgb(180, 0, 90));
}
.create_order_package_panel_card {
  @include panel_card(green);
}
.report_card_span {
  position: absolute;
  top: 0em;
  right: 0em;
  font-weight: 700;
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #a0a0a0;
  /*border:1px solid;*/
  text-align: right;
  padding: 15px;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
