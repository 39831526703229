@mixin text_style($bg, $tc, $bs1: '', $bs2: '') {
  background-color: $bg;
  color: $tc;
  padding: 4px;
  margin-right: 6px;
  border-radius: 2px;
  background: linear-gradient(145deg, $bs1, $bs2);
  font-weight: 500;
  font-size: 12px;
}

.right_side_header {
  float: right;
  height: 80%;
}

.right_side_header_all_api_calls {
  @include text_style(#cce5fe, #2e5089, #c6def6, #d2ecff);
}

.right_side_header_success_api_calls {
  @include text_style(#d4edda, #225e65, #cee6d3, #daf4e1);
}
.right_side_header_failed_api_calls {
  @include text_style(#f8d7da, #a12e24, #f1d1d3, #ffdde1);
}
